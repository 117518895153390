import { FC, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import Select, {
  components,
  DropdownIndicatorProps,
  OptionProps,
  SingleValueProps,
  StylesConfig,
} from 'react-select';

import { ChevronDownIcon } from '../../assets/svg/ChevronDownIcon';
import styles from './Dropdown.module.scss';
import { ICustomSelect, IOption } from './types';

interface ISelectProps extends ICustomSelect {
  onChange?: (value: string) => void;
  defaultValue?: { value: string; label: string };
  value?: { value: string; label: string };
}
interface IDropdownIndicatorProps extends DropdownIndicatorProps {
  dropdownIndicatorClassName?: string;
}

interface ISingleValueProps extends SingleValueProps<IOption> {
  isLoading?: boolean;
}

const DropdownIndicator = ({ dropdownIndicatorClassName, ...props }: IDropdownIndicatorProps) => {
  return (
    <components.DropdownIndicator {...props}>
      <ChevronDownIcon className={dropdownIndicatorClassName} />
    </components.DropdownIndicator>
  );
};

const SingleValue = ({ isLoading, ...props }: ISingleValueProps) => {
  const { children, data } = props || {};
  const { icon } = data || {};

  return (
    <components.SingleValue {...props}>
      {isLoading ? (
        <div className={styles.iconPreview}>
          <Skeleton />
        </div>
      ) : icon ? (
        <img className={styles.optionIcon} src={icon} alt="icon" width="20" height="20" />
      ) : null}
      <div className={styles.singleValue}>
        {isLoading ? (
          <div className={styles.singleValuePreview}>
            <Skeleton />
          </div>
        ) : (
          children
        )}
      </div>
    </components.SingleValue>
  );
};

const Option = (props: OptionProps<IOption>) => {
  const { children, data } = props || {};
  const { icon } = data || {};

  return (
    <components.Option {...props}>
      {icon && <img className={styles.optionIcon} src={icon} alt="icon" width="20" height="20" />}
      <div className={styles.option}>{children}</div>
    </components.Option>
  );
};

export const Dropdown: FC<ISelectProps> = ({
  options,
  name,
  ariaLabel,
  dropdownIndicatorClassName,
  isFullWidth,
  onChange,
  customDropdownStyles,
  defaultValue,
  isLoading,
  value,
  isDisabled,
  isControlHovered,
}) => {
  const [isDropdownIndicatorRotated, setIsDropdownIndicatorRotated] = useState(false);

  const dropdownStyles: StylesConfig = {
    container: baseStyles => ({
      ...baseStyles,
      position: 'relative',
      background: 'rgba(255, 255, 255, 0.04)',
      borderRadius: '8px',
      boxShadow:
        '0px 0px 0px 0.5px rgba(255, 255, 255, 0.16) inset, 0px 0px 0px 1px rgba(0, 0, 0, 0.25)',
      border: '1px solid rgba(255, 255, 255, 0.08)',
      width: isFullWidth ? '100%' : 'fit-content',
      minWidth: '120px',
      maxWidth: '320px',
      cursor: 'pointer',
      ...customDropdownStyles?.container,
    }),
    control: () => ({
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: '8px',
      padding: '9px 16px',
      borderRadius: '8px',
      '&:hover': {
        background: isControlHovered ? 'rgba(255, 255, 255, 0.1)' : 'none',
      },
      ...customDropdownStyles?.control,
    }),
    indicatorSeparator: () => ({
      display: 'none',
      ...customDropdownStyles?.indicatorSeparator,
    }),
    indicatorsContainer: () => ({
      ...customDropdownStyles?.indicatorsContainer,
    }),
    valueContainer: () => ({
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      overflow: 'hidden',
      ...customDropdownStyles?.valueContainer,
    }),
    dropdownIndicator: () => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      transform: isDropdownIndicatorRotated ? 'rotateX(180deg)' : 'none',
      ...customDropdownStyles?.dropdownIndicator,
    }),
    singleValue: () => ({
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      color: 'var(--app-text)',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      whiteSpace: 'nowrap',
      ...customDropdownStyles?.singleValue,
    }),
    input: () => ({
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      ...customDropdownStyles?.input,
    }),
    menu: baseStyles => ({
      ...baseStyles,
      border: '1px solid rgba(255, 255, 255, 0.08)',
      borderRadius: '8px',
      background: 'rgba(33, 36, 39, 0.56)',
      boxShadow: '0px 12px 30px 12px rgba(0, 0, 0, 0.25)',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      backdropFilter: 'blur(16px)',
      padding: '3px',
      marginTop: '8px',
      left: 0,
      ...customDropdownStyles?.menu,
    }),
    menuList: baseStyles => ({
      ...baseStyles,
      display: 'flex',
      flexDirection: 'column',
      gap: '4px',
      borderRadius: '8px',
      padding: 0,
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      maxHeight: '304px',
      ...customDropdownStyles?.menuList,
    }),
    option: (baseStyles, { isFocused, isSelected }) => ({
      ...baseStyles,
      display: 'flex',
      alignItems: 'center',
      gap: '8px',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
      color: isFocused || isSelected ? '#F3F4F5' : '#B1B4B7',
      background:
        isFocused || isSelected
          ? 'linear-gradient(180deg, rgba(255, 255, 255, 0.08) 0%, rgba(255, 255, 255, 0.00) 100%)'
          : '',
      padding: '0 10px',
      height: '40px',
      whiteSpace: 'nowrap',
      overflowX: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
      borderRadius: '8px',
      '&:active': {
        background: '#2F3234',
      },
      flexShrink: 0,
      cursor: 'pointer',
      ...customDropdownStyles?.option,
    }),
    noOptionsMessage: () => ({
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '10px 16px',
      background: '#2F3234',
      color: '#D8DADB',
      fontSize: '14px',
      fontWeight: '500',
      lineHeight: '20px',
    }),
  };

  return (
    <Select
      value={value}
      onChange={(val: { value: string; label: string }) => {
        onChange(val?.value);
      }}
      defaultValue={defaultValue ?? options[0] ?? { value: '', label: '' }}
      name={name}
      options={options}
      aria-label={ariaLabel}
      blurInputOnSelect
      components={{
        DropdownIndicator: props => (
          <DropdownIndicator {...props} dropdownIndicatorClassName={dropdownIndicatorClassName} />
        ),
        SingleValue: props => (
          <SingleValue {...(props as ISingleValueProps)} isLoading={isLoading} />
        ),
        Option,
      }}
      styles={dropdownStyles}
      isSearchable={false}
      isDisabled={isLoading || isDisabled}
      onMenuOpen={() => setIsDropdownIndicatorRotated(true)}
      onMenuClose={() => setIsDropdownIndicatorRotated(false)}
      placeholder=""
      unstyled
    />
  );
};
