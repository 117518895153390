import React from 'react';
import Skeleton from 'react-loading-skeleton';

import { ICustomPrompt } from '../../../../models/IPromptLibrary';
import { promptsPreview } from '../../../../utils/skeletonPreview';
import { AddNewPromptTemplate } from '../AddNewPromptTemplate';
import { CustomPrompt } from '../CustomPrompt/CustomPrompt';
import styles from './CustomPromptsTabContent.module.scss';

interface ICustomPrompts {
  customPrompts: ICustomPrompt[];
  onEdit: (prompt: ICustomPrompt) => () => void;
  isLoading: boolean;
  onAdd: () => void;
  onDelete: (prompt: ICustomPrompt) => void;
  onAddToCommand: (prompt: ICustomPrompt, promptType: number) => void;
  onPromptSelect: (id: number) => void;
}

export const CustomPromptsTabContent: React.FC<ICustomPrompts> = ({
  customPrompts,
  onEdit,
  onDelete,
  isLoading,
  onAdd,
  onAddToCommand,
  onPromptSelect,
}) => {
  return (
    <>
      {isLoading ? (
        <div className={styles.promptLibraryPromptsPreviewContainer}>
          {promptsPreview.map(preview => (
            <div key={preview.id} className={styles.customPromptPreview}>
              <div className={styles.header}>
                <div className={styles.leftPart}>
                  <Skeleton />
                </div>

                <div className={styles.rightPart}>
                  <Skeleton />
                  <Skeleton />
                  <Skeleton />
                </div>
              </div>
              <hr />

              <div className={styles.title}>
                <Skeleton />
              </div>
              <div className={styles.description}>
                <Skeleton />
                <Skeleton className="short" />
              </div>
              <div className={styles.otherInfo}>
                <div className={styles.leftPart}>
                  <Skeleton />
                </div>
                <div className={styles.rightPart}>
                  <Skeleton />
                  <Skeleton />
                </div>
              </div>
            </div>
          ))}
        </div>
      ) : (
        <div className={styles.promptLibraryPromptsContainer}>
          <AddNewPromptTemplate onClick={onAdd} />
          {customPrompts?.map(prompt => {
            return (
              <CustomPrompt
                key={prompt?.id}
                prompt={prompt}
                onEdit={onEdit(prompt)}
                onDelete={() => {
                  onDelete(prompt);
                }}
                onAddToCommand={onAddToCommand}
                onPromptSelect={onPromptSelect}
              />
            );
          })}
        </div>
      )}
    </>
  );
};
