import './CustomPrompt.external.scss';

import { FC, MouseEvent, useState } from 'react';
import toast from 'react-hot-toast';

import { Badge } from '../../../../components/Badge';
import { DropdownIcon } from '../../../../external/extension/ui/design/assets/svg/icons/DropdownIcon';
import EditIcon from '../../../../external/extension/ui/design/assets/svg/icons/EditIcon';
import { ShareIcon } from '../../../../external/extension/ui/design/assets/svg/icons/ShareIcon';
import { SlashIcon } from '../../../../external/extension/ui/design/assets/svg/icons/SlashIcon';
import { TrashIcon } from '../../../../external/extension/ui/design/assets/svg/icons/TrashIcon';
import { Avatar } from '../../../../external/extension/ui/design/components/avatar';
import { Dropdown } from '../../../../external/extension/ui/design/components/dropdown';
import { ICustomPrompt } from '../../../../models/IPromptLibrary';
import { ICategory } from '../../../../models/IShared';
import { PAGES_ENUM } from '../../../../utils/constants';
import { UsageAndViewBadges } from '../UsageAndViewBadges';
import styles from './CustomPrompt.module.scss';

type Props = {
  prompt: ICustomPrompt;
  onEdit: () => void;
  onDelete: () => void;
  onAddToCommand: (prompt: ICustomPrompt, promptType: number) => void;
  onPromptSelect: (id: number) => void;
};

export const CustomPrompt: FC<Props> = ({
  prompt,
  onEdit,
  onDelete,
  onAddToCommand,
  onPromptSelect,
}) => {
  const {
    id,
    categories,
    name,
    description,
    click_amount,
    amount_of_lookups,
    user,
    editable,
    is_in_command,
  } = prompt;
  const { username, avatar } = user || {};

  const hasCategories = categories?.length !== 0;

  const handlePromptSelection = (e: MouseEvent<HTMLElement>) => {
    const target = e.target as HTMLElement;

    if (!target.closest('.alchemy-dropdown-wrapper')) {
      onPromptSelect(id);
    }
  };

  const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);

  return (
    <div
      className={
        styles.customPrompt + ' ' + (isDropdownOpen ? 'custom-prompt-with-open-dropdown' : '')
      }
      onClick={handlePromptSelection}
    >
      <div
        className={styles.customPromptHeading}
        style={{ justifyContent: hasCategories ? 'space-between' : 'flex-end' }}
      >
        <div className={styles.categoriesWrapper}>
          {(categories as ICategory[])?.map(category => {
            return (
              <Badge
                key={category?.id}
                icon={category?.icon}
                title={category?.parent?.name ? category.parent.name : category?.name}
                color={category?.color ?? '#0BA5EC'}
                background={category?.background_color ?? 'rgba(11, 165, 236, 0.16)'}
                subcategory={category?.parent && category?.name}
              />
            );
          })}
        </div>

        <div className={styles.customPromptButtonsContainer}>
          <Dropdown
            horizontalPosition="right"
            onOpen={() => setIsDropdownOpen(true)}
            onClose={() => {
              setTimeout(() => {
                setIsDropdownOpen(false);
              }, 300);
            }}
            items={[
              ...(editable
                ? [
                    {
                      kind: 'item',
                      icon: <ShareIcon />,
                      text: 'Share prompt',
                      onClick: async e => {
                        e.preventDefault();
                        await navigator.clipboard.writeText(
                          `${window.location.origin}${PAGES_ENUM.SHARED_CUSTOM_PROMPT}/${id}`
                        );
                        toast.success('Successfully copied to clipboard');
                      },
                    },
                  ]
                : ([] as any)),
              {
                kind: 'item',
                icon: <EditIcon color="currentColor" />,
                text: 'Edit prompt',
                onClick: e => {
                  e.preventDefault();
                  onEdit();
                },
              },
              {
                kind: 'item',
                icon: <SlashIcon style={{ minWidth: 16 }} width="16" height="16" />,
                text: is_in_command ? 'Remove from quick prompts' : 'Add to quick prompts',
                onClick: e => {
                  e.preventDefault();

                  if (is_in_command) {
                    toast.success('Removed from quick prompts');
                  } else {
                    toast.success('Added to quick prompts');
                  }

                  onAddToCommand(prompt, 1);
                },
              },
              {
                kind: 'item',
                icon: <TrashIcon width="16" height="16" viewBox="0 0 24 24" />,
                text: 'Delete prompt',
                onClick: e => {
                  e.preventDefault();
                  onDelete();
                },
              },
            ]}
          >
            <DropdownIcon />
          </Dropdown>
        </div>
      </div>
      <div className={styles.customPromptContent}>
        <div className={styles.customPromptTitleAndDescription}>
          <h3 className={styles.customPromptTitle}>{name}</h3>
          <p className={styles.customPromptDescription}>{description}</p>
        </div>
        <div className={styles.customPromptUserAndEngagementInfo}>
          <UsageAndViewBadges usage={click_amount} views={amount_of_lookups} />
          {username && (
            <div className={styles.customPromptUserInfo}>
              <Avatar size="nano" userName={username} url={avatar} />
              <span>{username}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
