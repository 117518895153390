import './MainContentLayout.external.scss';

import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import { Button, IButton } from '../../../external/extension/ui/design/components/button';
import { GlobalSearchBar } from '../../GlobalSearchBar';
import styles from './MainContentLayout.module.scss';

type Props = {
  title: string;
  actionButton?: IButton;
  searchBarName?: string;
  searchBarPlaceholder?: string;
  onChange?: (value: string) => void;
  onInstantChange?: (value: string) => void;
  children: ReactNode;
  extraHeaderContent?: ReactNode;
  isLoading?: boolean;
  isRenderSearch?: boolean;
  searchValue?: string;
  isSearchDisabled?: boolean;
  className?: string;
};

export const MainContentLayout: FC<Props> = ({
  title,
  children,
  isLoading,
  isRenderSearch = true,
  onChange = () => null,
  onInstantChange = () => null,
  isSearchDisabled = false,
  searchBarName,
  searchBarPlaceholder,
  extraHeaderContent,
  className,
  ...rest
}) => {
  return (
    <div className={classNames(styles.mainContent, 'generic-content-layout', className)}>
      <header className={styles.mainTitleAndSearchBarContainer}>
        <h1 className={styles.mainTitle}>
          <span>{title}</span>
          {rest.actionButton && <Button {...{ ...rest.actionButton, size: 'mini' }} />}
        </h1>
        <div className={styles.mainSearchBarWrapper}>
          {extraHeaderContent}
          {isRenderSearch && (
            <GlobalSearchBar
              {...rest}
              name={searchBarName}
              placeholder={searchBarPlaceholder}
              onChange={onChange}
              onInstantChange={onInstantChange}
              isLoading={isLoading}
              isDisabled={isSearchDisabled}
            />
          )}
        </div>
      </header>
      {children}
    </div>
  );
};
