import { ReactNode } from 'react';

export interface IAlchemyProfile {
  id: number;
  username: string;
  email: string;
  first_name?: string;
  last_name?: string;
  avatar?: string;
  register_provider: string;
  referral_code: string;
  subscription_tier: number;
  language_preference: string;
  hide_style_button: boolean;
  hide_tone_button: boolean;
  hide_ai_recommendations: boolean;
  in_waiting_list: boolean;
  subscription_end_date: string;
  show_again: boolean;
  is_canceled_subscription: boolean;
  onboarding_passed: boolean;
  trial_activated?: boolean;
}

export interface IAlchemySession {
  token: string;
  tier: number | null;
  trial_activated?: boolean;
}

export interface ILoginRequest {
  email: string;
  password: string;
}

export interface IAuthResult {
  auth_token: string;
  user: IAlchemyProfile;
}

export interface ISendResetPasswordCode {
  email: string;
}

export interface ICheckResetPasswordCode {
  email: string;
  code: string;
}

export interface IResetPassword {
  email: string;
  code: string;
  password: string;
}

export interface IReportingError {
  user: string;
  error_section: string;
  error_json: object;
  status_code: number;
}

export enum DEF_CATEGORY_ENUM {
  CUSTOM = 1000,
  FAVORITE = 1001,
}

export interface IPromptListParams {
  params: {
    text?: string;
    categories?: number | null;
    sort?: number | string;
    favorite?: boolean;
  };
}

export interface ISetFavoriteRequest {
  favourite: boolean;
  prompt_id: number;
}

enum PROMPT_TYPE {
  CUSTOM = 1,
  OTHER = 2,
}

export interface IAddToCommand {
  prompt_pk: number;
  prompt_type: PROMPT_TYPE;
  command: string;
  is_add: boolean;
}

export interface IPromptStep {
  id: number;
  step_number: number;
  prompt_template: string;
  prompt_markdown_template: string;
  user_prompt?: number;
}

export interface ICategory {
  id: number;
  name: string;
  color: string | null;
  icon: string | null;
  white_icon: string | null | ReactNode;
  parent?: { name: string };
  stroke_color: string | null;
  background_color: string | null;
  isAccess?: boolean;
  isPro?: boolean;
  available?: boolean;
  hasCustomIcon?: boolean;
  availableManualCheck?: boolean;
}

export interface IInput {
  _invalid?: boolean;
  _prompt?: number;
  variable_name: string;
  placeholder: string;
  is_textarea: boolean;
}

export interface IPrompt {
  id: number;
  steps: IPromptStep[];
  categories: ICategory[];
  is_favourite: boolean;
  like_amount: number;
  is_liked: boolean;
  creator: ICreator;
  inputs: IInput[];
  is_in_command: boolean;
  name: string;
  description: string;
  preview_description: null | string;
  created_at: string;
  amount_of_lookups: number;
  prompt_template: string;
  click_amount: number;
  has_plugins?: boolean;
  need_bing?: boolean;
  plugins: IPlugin[];
  html_template?: string;
  chain_prompt?: boolean;
  currentChainStep: number;
}

export interface IPlugin {
  id: number;
  name: string;
  link: string;
}

export interface IClickPrompt {
  prompt_extension_pk: number;
}

export interface ICreateDocumentRequest {
  file: string;
  name: string;
}

export interface IDocumentBase {
  category?: string | number | null;
  file?: string;
  name?: string;
}

export interface IDocument extends IDocumentBase {
  id: number;
  deleted: boolean;
  created_at: string;
  deleted_at: string | null;
  creator: number;
}

export interface IMoveDocumentRequest {
  type: number;
  category_id: number | null;
}

export interface IDocumentFolderBase {
  name: string;
}

export interface IDocumentFolder extends IDocumentFolderBase {
  id?: number;
  pk: number;
  documents_count: number;
  deleted: false;
  created_at: string;
  child_ids: number[];
}

export interface IDocumentsAndFolders {
  documents: IDocument[];
  categories: IDocumentFolder[];
}

export interface IParamsGetDocumentList {
  search: string;
}

export interface IReplaceExistingDocument {
  id: number;
  body: FormData;
}

export enum SUBSCRIPTION_TYPE {
  MONTH = '1',
  YEAR = '2',
}

export enum SUBSCRIPTION_TIER {
  ZERO = '0',
  ONE = '1',
  TWO = '2',
  THREE = '3',
}

export interface ICreatePremiumSubscriptionRequest {
  type: SUBSCRIPTION_TYPE;
  successRedirectUrl: '1' | '2';
  tier: SUBSCRIPTION_TIER;
}

export interface ICategory {
  id: number;
  name: string;
  color: string | null;
  icon: string | null;
  white_icon: string | null | ReactNode;
  parent?: { name: string };
  stroke_color: string | null;
  background_color: string | null;
  isAccess?: boolean;
  isPro?: boolean;
  available?: boolean;
  hasCustomIcon?: boolean;
  availableManualCheck?: boolean;
}

interface ICreator {
  pk: number;
  avatar: string | null;
  username: string;
  first_name: string;
  last_name: string;
}

export interface IPromptStep {
  id: number;
  step_number: number;
  prompt_template: string;
  prompt_markdown_template: string;
  user_prompt?: number;
}

export interface IPrompt {
  id: number;
  steps: IPromptStep[];
  categories: ICategory[];
  is_favourite: boolean;
  like_amount: number;
  is_liked: boolean;
  creator: ICreator;
  inputs: IInput[];
  is_in_command: boolean;
  name: string;
  description: string;
  preview_description: null | string;
  created_at: string;
  amount_of_lookups: number;
  prompt_template: string;
  click_amount: number;
  has_plugins?: boolean;
  need_bing?: boolean;
  plugins: IPlugin[];
  html_template?: string;
  chain_prompt?: boolean;
  currentChainStep: number;
}

export interface IPromptCreation {
  name: string;
  description: string;
  categories: number[];
  user: number | null;
  prompt_template: string;
  prompt_markdown_template: string;
  chain_prompt?: boolean;
}

export interface IPlugin {
  id: number;
  name: string;
  link: string;
}

export interface ICommand {
  id: number;
  command: string;
  prompt: string;
  input_fields: IInput[];
  related_prompt?: IPrompt;
  is_public?: boolean;
  is_quick_prompt?: boolean;
  created_at?: string;
  creator?: number;
  related_user_prompt?: number;
}

export interface ITextareaCommand {
  _hovered?: boolean;
  prompt: string;
  command: string;
  related_prompt: null | IPrompt;
  description?: string;
  input_fields?: IInput[];
}

export interface ITextareaCommandBE {
  prompt: string;
  command: string;
  related_prompt: null | IPrompt;
  input_fields?: IInput[];
}
