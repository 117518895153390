import classNames from 'classnames';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';

import { useExtensionEventListener } from '../../../external/extension/service/events';
import { ExtensionEventType } from '../../../external/extension/service/events/types';
import { UpgradeConfirmationModal } from '../../../external/extension/ui/components/upgrade/confirmation';
import { UpgradePricesModal } from '../../../external/extension/ui/components/upgrade/prices';
import { Sidebar } from '../../Sidebar';
import { MaxWidthContainer } from '../MaxWidthContainer';
import styles from './DashboardLayout.module.scss';

export const DashboardLayout = () => {
  const [isShowUpgradePopup, setIsShowUpgradePopup] = useState(false);
  const [isShowPricePopup, setIsShowPricePopup] = useState(false);

  useExtensionEventListener(ExtensionEventType.ShowUpgradeWindow, () => {
    setIsShowUpgradePopup(true);
  });

  useExtensionEventListener(ExtensionEventType.ShowUpgradePriceWindow, () => {
    setIsShowPricePopup(true);
  });

  return (
    <>
      <MaxWidthContainer>
        <div className={classNames(styles.dashboardLayoutContent, 'dashboard-layout')}>
          <Sidebar />
          <div className={classNames(styles.dashboardLayoutMainContent, 'dashboard-layout-main')}>
            <Outlet />
          </div>
        </div>
      </MaxWidthContainer>
      <UpgradePricesModal
        visible={isShowPricePopup}
        onClose={() => {
          setIsShowPricePopup(false);
        }}
      />

      <UpgradeConfirmationModal
        visible={isShowUpgradePopup}
        onUpgrade={() => {
          setIsShowPricePopup(true);
        }}
        onClose={() => setIsShowUpgradePopup(false)}
      />
    </>
  );
};
