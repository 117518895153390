import { useEffect } from 'react';
import {
  Navigate,
  Route,
  Routes as DomRoutes,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';

import { DashboardLayout } from './components/Layouts/DashboardLayout';
import { RequireAuth } from './components/RequireAuth';
import { useExtensionEventListener } from './external/extension/service/events';
import { ExtensionEventType } from './external/extension/service/events/types';
import { AuthSubBlock } from './external/extension/ui/components/auth';
import { useLogOut } from './hooks/useLogOut';
import { useScrollTop } from './hooks/useScrollTop';
import { useTypedDispatch } from './hooks/useTypedDispatch';
import { useTypedSelector } from './hooks/useTypedSelector';
import { IUser } from './models/ISettings';
import { Auth } from './pages/Auth/Auth';
import { ChatHistoryPage } from './pages/ChatHistoryPage';
import { ChatPage } from './pages/ChatPage';
import { ClipsPage } from './pages/ClipsPage';
import { DestroyAccount } from './pages/DestroyAccount';
import { KnowledgePage } from './pages/KnowledgePage';
import { MyContentPage } from './pages/MyContentPage';
import { NotFoundPage } from './pages/NotFoundPage';
import { Billing } from './pages/Onboarding/Billing';
import { OnboardingFirstStep } from './pages/Onboarding/OnboardingFirstStep';
import { OnboardingRedirect } from './pages/Onboarding/OnboardingRedirect';
import { OnboardingSecondStep } from './pages/Onboarding/OnboardingSecondStep';
import { RequireOnboarding } from './pages/Onboarding/RequireOnboarding';
import { PromptLibraryPage } from './pages/PromptLibraryPage';
import { SettingsPage } from './pages/SettingsPage';
import { SettingsTabs } from './pages/SettingsPage/components/SettingTab/types';
import { SharedPromptPage } from './pages/ShareCustomPrompt/SharedPromptPage';
import { SharePromptPage } from './pages/ShareCustomPrompt/SharePromptPage';
import { SnippetsPage } from './pages/SnippetsPage';
import { TermsOfServicePage } from './pages/TermsOfServicePage';
import { TrashPage } from './pages/Trash';
import {
  selectIsRedirectTo404,
  selectIsRedirectToLogin,
  setRedirectTo404,
  setRedirectToLogin,
} from './redux/Auth';
import {
  selectIsRedirectToUpgradeSubscriptionPage,
  setIsRedirectToUpgradeSubscriptionPage,
} from './redux/Subscription';
import { useLazyGetSettingsQuery } from './services/settings';
import { getIsUserAuthorizedWithGoogle } from './services/storageService';
import { PAGES_ENUM } from './utils/constants';
import { handleError } from './utils/notifications';

export const Routes = () => {
  const [searchParams] = useSearchParams();
  const dispatch = useTypedDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const isRedirectTo404 = useTypedSelector(selectIsRedirectTo404);
  const isRedirectToLogin = useTypedSelector(selectIsRedirectToLogin);
  const isRedirectToUpgradeSubscriptionPage = useTypedSelector(
    selectIsRedirectToUpgradeSubscriptionPage
  );

  const isGoogleAuthenticate = getIsUserAuthorizedWithGoogle();
  const { onLogout } = useLogOut();

  const [getProfile] = useLazyGetSettingsQuery();

  useScrollTop();

  const processExternalAuthToken = async (token: string) => {
    if (!token) {
      navigate(PAGES_ENUM.SIGN_IN, { replace: true });

      return;
    }

    localStorage.setItem('auth_token', token);

    const resProfile = (await getProfile()) as { data: IUser & { status: number } };

    if (resProfile?.data?.status >= 200 && resProfile.data?.status < 400) {
      const data = resProfile.data;

      localStorage.setItem('onboarding_passed', String(data?.onboarding_passed));

      if (data?.onboarding_passed) {
        navigate(PAGES_ENUM.HOME, { replace: true });
      } else {
        navigate(PAGES_ENUM.ONBOARDING_FIRST_STEP, { replace: true });
      }
    } else {
      localStorage.removeItem('auth_token');
      navigate(PAGES_ENUM.SIGN_IN, { replace: true });
    }
  };

  const redirectFromExtensionByMainPage = async () => {
    const token = searchParams.get('token');

    processExternalAuthToken(token);
  };

  useExtensionEventListener(
    ExtensionEventType.CastAuthorization,
    e => {
      const authToken = localStorage.getItem('auth_token') || sessionStorage.getItem('auth_token');

      if (e.data?.token && !authToken) {
        processExternalAuthToken(e.data.token);
      }
    },
    []
  );

  useExtensionEventListener(ExtensionEventType.LogoutInBlobExtensionDone, e => {
    onLogout(isGoogleAuthenticate, true);
  });

  useEffect(() => {
    const isStripeFailed = searchParams.get('stripe-failed');

    if (isStripeFailed) {
      handleError('Payment failed');
      navigate(window.location.pathname);
    }
  }, []);

  useEffect(() => {
    if (isRedirectToUpgradeSubscriptionPage === false) return;

    navigate(`${PAGES_ENUM.SETTINGS}/?tab=${SettingsTabs.BILLING_AND_PLANS}`);
    dispatch(setIsRedirectToUpgradeSubscriptionPage(false));
  }, [isRedirectToUpgradeSubscriptionPage]);

  useEffect(() => {
    if (!isRedirectToLogin) return;

    navigate(PAGES_ENUM.SIGN_IN);
    dispatch(setRedirectToLogin(false));
  }, [isRedirectToLogin]);

  useEffect(() => {
    if (!isRedirectTo404) return;

    navigate(PAGES_ENUM.NOT_FOUND_404);
    dispatch(setRedirectTo404(false));
  }, [isRedirectTo404]);

  useEffect(() => {
    if (location.pathname.includes('login-to-dashboard')) {
      redirectFromExtensionByMainPage();
    }
  }, []);

  if (location.pathname.includes('login-to-dashboard')) {
    return null;
  }

  return (
    <DomRoutes>
      <Route element={<RequireAuth />}>
        <Route element={<RequireOnboarding />}>
          <Route path={PAGES_ENUM.HOME} element={<DashboardLayout />}>
            <Route index element={<Navigate to={PAGES_ENUM.CHAT} />} />
            <Route path={PAGES_ENUM.PROMPTS} element={<PromptLibraryPage />} />
            <Route path={PAGES_ENUM.CHAT_HISTORY} element={<ChatHistoryPage />} />
            <Route path={PAGES_ENUM.CHAT} element={<ChatPage />} />
            <Route path={PAGES_ENUM.SYNTH} element={<ChatPage mode={'synth'} />} />
            <Route path={PAGES_ENUM.AI_SEARCH} element={<ChatPage mode={'search'} />} />
            <Route path={PAGES_ENUM.CLIPBOARD} element={<ClipsPage />} />
            <Route path={PAGES_ENUM.ALL_CONTENT} element={<MyContentPage />} />
            <Route path={PAGES_ENUM.SETTINGS} element={<SettingsPage />} />
            <Route path={PAGES_ENUM.TRASH} element={<TrashPage />} />
            <Route path={PAGES_ENUM.SNIPPETS} element={<SnippetsPage />} />
            <Route path={PAGES_ENUM.KNOWLEDGE} element={<KnowledgePage />} />
          </Route>
          <Route path={PAGES_ENUM.SHARE_CUSTOM_PROMPT_PARAMETRIZED} element={<SharePromptPage />} />
        </Route>
        <Route element={<OnboardingRedirect />}>
          <Route path={PAGES_ENUM.ONBOARDING_FIRST_STEP} element={<OnboardingFirstStep />} />
          <Route path={PAGES_ENUM.ONBOARDING_SECOND_STEP} element={<OnboardingSecondStep />} />
          <Route path={PAGES_ENUM.ONBOARDING_PAYMENT} element={<Billing />} />
        </Route>
      </Route>
      <Route path={PAGES_ENUM.TERMS_OF_SERVICE} element={<TermsOfServicePage />} />
      <Route path={PAGES_ENUM.SIGN_UP} element={<Auth defaultBlock={AuthSubBlock.SignUp} />} />
      <Route path={PAGES_ENUM.SIGN_IN} element={<Auth defaultBlock={AuthSubBlock.Login} />} />
      <Route path={PAGES_ENUM.SHARED_CUSTOM_PROMPT_PARAMETRIZED} element={<SharedPromptPage />} />
      <Route
        path={PAGES_ENUM.FORGOT_PASSWORD}
        element={<Auth defaultBlock={AuthSubBlock.ResetPassword} />}
      />
      <Route
        path={PAGES_ENUM.RESET_PASSWORD}
        element={<Auth defaultBlock={AuthSubBlock.ResetPassword} />}
      />
      <Route
        path={PAGES_ENUM.CODE_CONFIRMATION}
        element={<Auth defaultBlock={AuthSubBlock.ResetPassword} />}
      />
      <Route path={PAGES_ENUM.DESTROY_ACCOUNT} element={<DestroyAccount />} />
      <Route path={PAGES_ENUM.NOT_FOUND_404} element={<NotFoundPage />} />
    </DomRoutes>
  );
};
