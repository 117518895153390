import 'highlight.js/styles/stackoverflow-dark.css';

import { Editor } from '@tiptap/core';
import Highlight from '@tiptap/extension-highlight';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TextAlign from '@tiptap/extension-text-align';
import Typography from '@tiptap/extension-typography';
import Underline from '@tiptap/extension-underline';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import debounce from 'lodash/debounce';
import { FC, useCallback, useState } from 'react';
import { Markdown } from 'tiptap-markdown';

import { TextEditor } from '../../../components/TextEditor';
import { highlightAndSanitizeHtml } from '../../../utils/helpers';
import { handleError } from '../../../utils/notifications';
import { MenuBarTipTap } from '../components/MenuBarTipTap/MenuBarTipTap';
import { useUpdateDocument } from '../useUpdateDocument';
import { useUpdateDocumentName } from '../useUpdateDocumentName';
import styles from './DocumentContent.module.scss';
import CodeBlockPrism from './plugins/prism';
import { IDocumentContent } from './types';

export const DocumentContent: FC<IDocumentContent> = ({ documentContent, query, isDeleted }) => {
  const [characterCount, setCharacterCount] = useState(0);
  const [showEmptyDocumentNameError, setShowEmptyDocumentNameError] = useState(false);
  const [wordCount, setWordCount] = useState(0);
  const { id: documentId, name: documentName, file } = documentContent || {};
  const { handleUpdateDocumentName } = useUpdateDocumentName();
  const { handleUpdateDocument } = useUpdateDocument();

  const debouncedUpdateDocument = useCallback(
    debounce((id: number, content: string) => {
      handleUpdateDocument(id, content);
    }, 1500),
    []
  );

  const sanitizedDocumentName = highlightAndSanitizeHtml(documentName, query, true);

  const editor = useEditor(
    {
      extensions: [
        StarterKit.configure({
          codeBlock: false,
        }),
        Markdown,
        Table.configure({
          resizable: false,
        }),
        TableRow,
        TableHeader,
        TableCell,
        Typography,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        CodeBlockPrism,

        Link.configure({
          openOnClick: false,
        }),
        Underline,
        Highlight.configure({ multicolor: true }),
      ],
      onUpdate: ({ editor }) => {
        const currentContent = editor.storage.markdown.getMarkdown();

        onEditorChange(editor);

        debouncedUpdateDocument(documentId, currentContent);
      },
      onCreate: ({ editor }) => {
        onEditorChange(editor);
      },
      content: `${(file ? file : '') + '\n\n<p></p>'}`,
      editable: !isDeleted,
    },
    [query]
  );

  const documentNameEditor = useEditor(
    {
      extensions: [
        StarterKit,
        Typography,
        TextAlign.configure({
          types: ['heading', 'paragraph'],
        }),
        Underline,
        Placeholder.configure({
          placeholder: 'Enter the name',
        }),
        Highlight.configure({ multicolor: true }),
      ],
      onUpdate: ({ editor }) => {
        const currentText = editor.getText();
        const limitedText = currentText.slice(0, 255);

        if (!currentText?.trim()?.length && !showEmptyDocumentNameError) {
          handleError("The name of the document can't be empty");
          setShowEmptyDocumentNameError(true);
        }

        if (currentText.length > 255) {
          // If new content length exceeds 255, prevent further input by setting the state back to the limit
          const newText = currentText.slice(0, 255);

          // set HTML content without emitting an update
          editor.commands.setContent(`<p>${newText}</p>`, false);
        }

        onChangeName(limitedText);
      },
      content: `<p>${sanitizedDocumentName}</p>`,
      editable: !isDeleted,
    },
    [query]
  );

  const onEditorChange = (editor: Editor) => {
    if (!editor) return;

    const htmlText = editor.getHTML();
    const intermediateText = htmlText.replace(/<\/[^>]+><[^>]+>/g, ' ');
    const cleanedText = intermediateText.replace(/<[^>]+>/g, '').trim();
    const words = cleanedText.split(' ').filter(word => word.length > 0);
    const wordCount = words.length;
    const characterCount = cleanedText.length;

    setWordCount(wordCount);
    setCharacterCount(characterCount);
  };

  const onChangeName = useCallback(
    debounce((newName: string) => {
      if (newName?.startsWith(' ') || newName?.length === 0) {
        return;
      }

      if (newName?.length > 255) {
        handleError('The name must not exceed 255 characters.');

        return;
      }

      handleUpdateDocumentName(documentId, newName);
    }, 1500),
    [documentId]
  );

  return (
    <>
      <div className={styles.documentContentWrapper}>
        <TextEditor editor={documentNameEditor} containerClassName={styles.documentTitle} />
        <TextEditor editor={editor} />
      </div>

      <div className={styles.toolbar}>
        {editor && (
          <>
            <MenuBarTipTap editor={editor} documentId={documentId} isTrash={isDeleted} />
            <div className={styles.toolbarContent}>
              <span className={styles.toolbarCharacters}>{`${characterCount} characters`}</span>
              <span>•</span>
              <span className={styles.toolbarWords}>{`${wordCount} words`}</span>
            </div>
          </>
        )}
      </div>
    </>
  );
};

DocumentContent.displayName = 'DocumentContent';
