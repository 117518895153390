import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { errorMessages } from '../../../services/errors';
import { useSetSettingsMutation } from '../../../services/settings';
import { PAGES_ENUM } from '../../../utils/constants';
import { handleError } from '../../../utils/notifications';
import { Category } from '../components/Category';
import { OnboardingStepLayout } from '../components/OnboardingStepLayout';
import styles from './OnboardingFirstStep.module.scss';

const categories = [
  {
    id: 1,
    title: 'Business & Finance',
  },
  {
    id: 2,
    title: 'Technology & Engineering',
  },
  {
    id: 3,
    title: 'Creative Arts & Design',
  },
  {
    id: 4,
    title: 'Education & Research',
  },
  {
    id: 5,
    title: 'Marketing & Ads',
  },
  {
    id: 6,
    title: 'Writing & Content Creation',
  },
  {
    id: 7,
    title: 'Others',
  },
];

export const OnboardingFirstStep = () => {
  const [chosenCategories, setChosenCategories] = useState([]);
  const navigate = useNavigate();
  const [setSettings] = useSetSettingsMutation();
  const handleCategoryClick = (title: string) => {
    if (!chosenCategories.includes(title)) {
      setChosenCategories(prev => [...prev, title]);
    } else {
      const filteredCategories = chosenCategories.filter(
        chosenCategory => chosenCategory !== title
      );

      setChosenCategories(filteredCategories);
    }
  };

  const handleSkip = () => {
    navigate(PAGES_ENUM.ONBOARDING_SECOND_STEP);
  };

  const handleNext = async () => {
    try {
      await setSettings({ onboarding_doings: chosenCategories }).unwrap();
      navigate(PAGES_ENUM.ONBOARDING_SECOND_STEP);
    } catch (error) {
      handleError(errorMessages.DEFAULT_ERROR_MESSAGE);
      console.error('User Update Failed', error);
    }
  };

  return (
    <OnboardingStepLayout
      title="What do you do?"
      description="Tell us what you do to curate your prompt list for you"
      onSkip={handleSkip}
      onNext={handleNext}
      currentStep={1}
    >
      <div className={styles.onboardingFirstStepCategoriesWrapper}>
        {categories.map(category => (
          <Category
            key={category.id}
            title={category.title}
            onClick={handleCategoryClick}
            isSelected={chosenCategories.includes(category.title)}
          />
        ))}
      </div>
    </OnboardingStepLayout>
  );
};
