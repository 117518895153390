export enum ExtensionEventType {
  Test = 'test',
  StopAllVoiceInputs = 'stop-all-voice-inputs',
  RefreshVoiceInputText = 'refresh-voice-input-text',
  CastAuthorization = 'cast-authorization',
  BlobExtensionLoaded = 'blob-extension-loaded',
  ShowUpgradeWindow = 'web-show-upgrade-window',
  ShowUpgradePriceWindow = 'web-show-upgrade-price-window',
  ScreenshotRequested = 'dashboard-screenshot-requested',
  ScreenshotReady = 'dashboard-screenshot-ready',
  BlobExtensionInstantLogout = 'blob-extension-instant-logout',
  LogoutInBlobExtensionDone = 'logout-in-blob-extension-done',
  RefreshSnippets = 'refresh-snippets',
  RefreshConversations = 'refresh-conversations',
  ShowQuickPrompts = 'web-show-quick-prompts',
  TurnOffLightTheme = 'web-turn-off-light-theme',
  TurnOnLightTheme = 'web-turn-on-light-theme',
}
