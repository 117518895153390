import './ChatsList.external.scss';

import detectUrlChange from 'detect-url-change';
import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  ConversationType,
  IConversation,
} from '../../../../external/extension/service/base/ai/interfaces';
import { useExtensionServiceContext } from '../../../../external/extension/service/context';
import { useExtensionEventListener } from '../../../../external/extension/service/events';
import { ExtensionEventType } from '../../../../external/extension/service/events/types';
import { useAsyncProcessManagerContext } from '../../../../external/extension/tools/async/context';
import ArrowDownIcon from '../../../../external/extension/ui/design/assets/svg/icons/ArrowDownIcon';
import { SmoothVisibility } from '../../../../external/extension/ui/design/components/smoothVisibility';
import { BackendHistoryStorageProvider } from '../../../../external/extension/ui/screens/history/providers/backend';

export interface IChatsList {
  visible: boolean;
}

export function ChatsList(props: IChatsList) {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  const [chats, setChats] = useState<IConversation[]>([]);
  const [refreshTrigger, setRefreshTrigger] = useState<boolean>(false);

  const extensionService = useExtensionServiceContext();
  const asyncProcessManager = useAsyncProcessManagerContext();

  const user = extensionService.useExtensionUser();
  const isAuthorized = extensionService.useExtensionAuthorization();

  const navigate = useNavigate();

  const history = useMemo(() => {
    return new BackendHistoryStorageProvider(extensionService);
  }, [extensionService, isAuthorized]);

  const [currentURL, setCurrentURL] = useState<string>(window.location.href);

  const activeId = useMemo(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const encryptedId = searchParams.get('id');

    if (encryptedId) {
      return extensionService.encryptor.decrypt(encryptedId);
    } else {
      return null;
    }
  }, [currentURL]);

  const refresh = () => setRefreshTrigger(prev => !prev);

  useEffect(() => {
    const updateURL = () => setCurrentURL(window.location.href);

    detectUrlChange.on('change', updateURL);

    return () => {
      detectUrlChange.off('change', updateURL);
    };
  }, []);

  useEffect(() => {
    if (!user.profile) return;

    asyncProcessManager?.doProcess({
      name: 'Fetch chats',
      action: async () => {
        const result = await history.getConversationsList();

        if (result) {
          setChats(result);
        }
      },
    });
  }, [user?.profile?.id, refreshTrigger]);

  useExtensionEventListener(ExtensionEventType.RefreshConversations, () => refresh());

  return (
    <SmoothVisibility visible={props.visible} className="sidebar-chats-list">
      {chats.length > 0 && (
        <div
          onClick={() => setIsExpanded(prev => !prev)}
          className={'heading' + (isExpanded ? ' expanded' : '')}
        >
          <span>Chats</span>
          <ArrowDownIcon />
        </div>
      )}
      <SmoothVisibility visible={isExpanded} className="chats">
        {chats.map((chat, index) => {
          const isActive = chat.id.toString() === activeId;

          return (
            <div
              onClick={() => {
                const id = extensionService.encryptor.encrypt(chat.id);

                navigate(
                  '/' +
                    (chat.chat_type === ConversationType.Synth ? 'synthesize' : 'chat') +
                    '?id=' +
                    id
                );
              }}
              key={index}
              className={'chat' + (isActive ? ' active' : '')}
            >
              {chat.name}
            </div>
          );
        })}
      </SmoothVisibility>
    </SmoothVisibility>
  );
}
